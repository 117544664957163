body.fullpageSidebar,
.pushable {
  overflow-y: hidden !important;
}

/* ------------------ */
/* React big calendar
/* ------------------ */

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  border-top: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-today {
  background-color: transparent !important;
}

/**
* ------------------------------------ 
* Events
* ------------------------------------ */
.rbc-event {
  border-radius: 0px !important;
}

// Default values
.rbc-event,
.rbc-event.rbc-selected,
.rbc-event.rbc-addons-dnd-drag-preview,
.rbc-event.rbc-addons-dnd-over {
  background-color: #6ac0c5 !important;
  border-color: darken(#6ac0c5, 5%) !important;
}

.event__wrapper--with--error .rbc-event,
.event__wrapper--with--error .rbc-event.rbc-selected,
.event__wrapper--with--error .rbc-event.rbc-addons-dnd-over {
  background-color: #ff5f58 !important;
  border: 1px solid #da4a43 !important;
}

// From color palette values
$colors-list: #6ac0c5 #2e9499 #6cbe9d #226e72;
@each $current-color in $colors-list {
  $i: index($colors-list, $current-color);
  .rbc-event.categoryID__#{($i - 1)},
  .rbc-event.categoryID__#{($i - 1)}.rbc-selected,
  .rbc-event.categoryID__#{($i - 1)}.rbc-addons-dnd-drag-preview,
  .rbc-event.categoryID__#{($i - 1)}.rbc-addons-dnd-over {
    background-color: $current-color !important;
    border-color: darken($current-color, 5%) !important;
  }
  .event__wrapper--with--error .rbc-event.categoryID__#{($i - 1)},
  .event__wrapper--with--error .rbc-event.categoryID__#{($i - 1)}.rbc-selected,
  .event__wrapper--with--error .rbc-event.categoryID__#{($i - 1)}.rbc-addons-dnd-over {
    background-color: #ff5f58 !important;
    border: 1px solid #da4a43 !important;
  }
}

.rbc-time-column:first-child .rbc-timeslot-group {
  border: none !important;
}

.rbc-header {
  text-transform: capitalize !important;
  font-size: 1em !important;
}

.rbc-event-content {
  font-size: 12px;
}

.rbc-header + .rbc-header,
.rbc-time-header-content,
.rbc-time-header.rbc-overflowing {
  border-left: none !important;
  border-right: none !important;
}

.rbc-label,
.rbc-header a > span,
.rbc-header > span,
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  font-size: 12px !important;
  color: #828282 !important;
  font-weight: 500 !important;
}

.rbc-header a > span {
  padding: 8px 0px;
}

.rbc-month-view {
  border-top: none !important;
}

.rbc-month-view {
  border: none !important;
}

// .rbc-btn-group:first-child button:first-child {
//   display: none !important;
// }

.rbc-toolbar-label {
  color: #828282;
  text-align: left !important;
  text-transform: capitalize;
}

.rbc-time-column {
  &:first-child {
    .rbc-timeslot-group {
      .rbc-time-slot.restrictedArea.active {
        background-color: transparent;
      }
    }
  }
  .rbc-timeslot-group {
    .rbc-time-slot.restrictedArea {
      &.active {
        background-color: #f2f2f2;
        border: none;
      }
    }
  }
}
